

const maxPlayers = 4;

class Card {
    constructor(name, count, description, powerLevel, targetable, imgsrc) {
        this.name = name;
        this.count = count;
        this.description = description;
        this.powerLevel = powerLevel;
        this.targetable = targetable;
        this.imgsrc = imgsrc;
    }
}

//16 cards total
const Guard = new Card('Guard', 5, "Guess a player's hand.", 1, true,require('./card-images/Guard_Gwent_Soldier.png'));

const Priest = new Card('Priest', 2, "Look at a hand.", 2, true, require('./card-images/Priest_Gwent_Philippa.png'));

const Baron = new Card('Baron', 2, "Compare hands; lower hand is out.", 3, true, require('./card-images/Baron_Gwent_Vernon.png'));

const Handmaid = new Card('Handmaid', 2, "Protection until your next turn.", 4, false, require('./card-images/Handmaid_Gwent_Triss.png'));

const Prince = new Card('Prince', 2, "One player discards his or her hand.", 5, true, require('./card-images/Prince_Gwent_Stennis.png'));

const King = new Card('King', 1, "Trade hands.", 6, true, require('./card-images/King_Gwent_Emhyr.png'));

const Countess = new Card('Countess', 1, "Discard if caught with King or Prince.", 7, false, require('./card-images/Countess_Gwent_Yennefer.png'));

const Princess = new Card('Princess', 1, "Lose if discarded.", 8, false, require('./card-images/Princess_Gwent_Cirilla.png'));

//Pick random card from deck provided
const pickRandomCard = (deck) => {
    let shuffleNumber = Math.floor(Math.random()*deck.length);
    let card = deck[shuffleNumber];

    deck.splice(deck.indexOf(card),1);

    return [card, deck];
}

//initialize with 1 burn card & declare card deck
const initDeck = () => {
    let cardTypes = [Guard, Priest, Baron, Handmaid, Prince, King, Countess, Princess];
    let Deck = cardTypes.reduce((accum, current) => {
        for(let j = 0; j < current.count; j++){
            let newAddedCard = new Card(current.name, current.count, current.description, current.powerLevel, current.targetable, current.imgsrc)
            accum.push(newAddedCard);
        }
        return accum;
    }, []);

    const [burnCard, deck] = pickRandomCard(Deck);
    
    return [burnCard, deck];

}

// const displayDiscardPile = (discardPile) => {
//     let discardList = document.getElementById('discard-list');
//     discardList.innerHTML = "";

//     discardPile.forEach(element => {
//         let newListItem = document.createElement('li');
//         newListItem.innerHTML = element.name;

//         discardList.appendChild(newListItem);
//     }); 
// }


let [burnCard, playDeck] = initDeck();

export { playDeck, burnCard, pickRandomCard, initDeck, maxPlayers };