import React from 'react';
import './App.css';
import '../LoveLetter/loveletter';
import { indexToNextPlayer } from '../TurnController/turncontroller'
import { pickRandomCard, initDeck, maxPlayers } from '../LoveLetter/loveletter';
import { Button } from '../Button/Button';
import { HandList } from '../HandList/HandList';
import { DiscardPile } from '../DiscardPile/DiscardPile';
import { PlayerSelector } from '../PlayerSelector/PlayerSelector';

class App extends React.Component {
  constructor(props){
    super(props);

    const [burnCard, playDeck] = initDeck();

    this.state = {
      playDeck,
      burnCard, 
      discardPile: [],
      currentCard: false,
      players: [],
      playerTargetable: [],
      currentPlayerIndex: 0
    }
    this.takeCardFunc = this.takeCardFunc.bind(this);
    this.resetDeckFunc = this.resetDeckFunc.bind(this);
    this.createPlayers = this.createPlayers.bind(this);
    this.givePlayerCard = this.givePlayerCard.bind(this);
    this.moveToNextPlayer = this.moveToNextPlayer.bind(this);
    this.playerCanPlay = this.playerCanPlay.bind(this);
  }

  takeCardFunc() {
    if(this.state.playDeck.length > 0){
        let card, newDeck;
        [card, newDeck] = pickRandomCard(this.state.playDeck);
        this.setState({
          playDeck: newDeck,
          discardPile: [...this.state.discardPile, card],
          currentCard: card
        });
    }
  }

  resetDeckFunc() {
      let newBurnCard, newDeck;
      [newBurnCard, newDeck] = initDeck();
      this.setState({
        playDeck: newDeck,
        burnCard: newBurnCard,
        discardPile: [],
        currentCard: false, 
        players: [],
        playerTargetable: [],
        currentPlayerIndex: 0
      });    
  }

  createPlayers(numOfPlayers) {
    let newPlayDeck = this.state.playDeck;
    let newPlayers = [];
    let newPlayersTargetable = [];
    let lastCard;

    for(let i=0; i< numOfPlayers; i++){
      const [card, newDeck] = pickRandomCard(newPlayDeck);
      newPlayDeck = newDeck;
      newPlayers.push([card]);
      lastCard = card;

      newPlayersTargetable.push(true);
    }
    
    this.setState({
      players: newPlayers,
      playDeck: newPlayDeck,
      currentCard: lastCard,
      numPlayers: numOfPlayers,
      playerTargetable: newPlayersTargetable
    }, () => {
      //console.log(this.state); 
    });

  }

  givePlayerCard(){ 
    let newPlayDeck = this.state.playDeck;
    const activePlayers = this.state.players;
    const currentPlayer = this.state.currentPlayerIndex;
    
    const [card, newDeck] = pickRandomCard(newPlayDeck);

    activePlayers[currentPlayer].push(card);

    this.setState({
      players: activePlayers,
      playDeck: newDeck,
      currentCard: card,
    });
  }

  moveToNextPlayer() {
    const numberOfPlayers = this.state.players.length;
    const currentPlayer= this.state.currentPlayerIndex;

    const nextPlayerIndex = indexToNextPlayer(currentPlayer, numberOfPlayers);
    
    this.setState({
      currentPlayerIndex: nextPlayerIndex 
    });
  }

  playerCanPlay() {
    const playerHandLength = this.state.players[this.state.currentPlayerIndex].length;  
    return (playerHandLength === 2);
  }

  render() {
    return (
      <div className="App">

          <header className="flex-container">
            <img 
            src={require('./GwentBanner.png')}
            alt="Game Sim Logo"
            />
            
            {this.state.players.length > 0 && (
            <p className="player-label">Player {this.state.currentPlayerIndex+1}'s Turn:</p>
            )}
            
            <nav>
              
            {!(this.state.players.length > 0) && (
              <div className="RightSideMenu">
                <PlayerSelector 
                  createPlayers={this.createPlayers} 
                  maxPlayers={maxPlayers}/>
              </div>
            )}  

            {this.state.players.length > 0 && (  
              <div className="player-actions"> 
                
                <Button 
                  className="nav-button"
                  onButtonClick={this.resetDeckFunc}>
                    Reset
                </Button>
                
                {!this.playerCanPlay() && (
                  <Button 
                    className="nav-button"
                    onButtonClick={this.givePlayerCard}>
                      Take Card
                  </Button>
                )}

                <Button 
                  className="nav-button"
                  onButtonClick={this.moveToNextPlayer}>
                    Next Player
                </Button>
              </div>
            )}

            </nav>
          </header>

          <main className="main">
            

            {this.state.currentCard && (
              <div className="board">
                <HandList 
                  playerhand={this.state.players[this.state.currentPlayerIndex]} 
                  currentPlayer={this.state.currentPlayerIndex}
                  playState={this.playerCanPlay()}
                  activePlayers={this.state.players.length}/>
                <p id="card-count">{`The remaining card count is ${this.state.playDeck.length}.`}</p>
              </div>
            )}
            
          {this.state.discardPile.length > 0 && (
            <DiscardPile pile={this.state.discardPile} deck={this.state.playDeck} burned={this.state.burnCard}/> 
          )}
          
          

          </main>

      </div>
    )
  };
}

export default App;
