import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { styled } from '@material-ui/core/styles';

//Takes in the Select material UI component, applies the styles to it, then creates the MySelect component which is styled
const MySelect = styled(Select)({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  width: '200px'
});

export class Dropdown extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          num: "unselected"
        }

        this.handleOnChange = this.handleOnChange.bind(this);        
      }

      numberOfPlayers(max) {
        const numberArray = [...Array(max).keys()]; // subtract by 1 since games can't have 1 player
        return numberArray.map(num => (<MenuItem value={num+1} key={num+1}>{num+1}</MenuItem>)); // add 2 to start at 2 players
      }

    handleOnChange(e) {
        this.setState({
            num: e.target.value
          });
          
        e.preventDefault();

        //user selects option from drop down and opponent is selected
    }

    
    render() {
        return (
        <div className="dropdown">
                <MySelect value={this.state.num} onChange={this.handleOnChange}>
                    <MenuItem value="unselected">--Target Player--</MenuItem>
                    {this.numberOfPlayers(this.props.activePlayers)}
                </MySelect> 
        </div>
        )
    }
}