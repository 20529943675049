import React from 'react';
import './Button.css';

const e = React.createElement;

export class Button extends React.Component {
    render() {
  
        return e(
          'button',
          { onClick: this.props.onButtonClick, className: this.props.className },
          this.props.children
        );

      }

}

