import React from 'react';
import { CardDisplay } from '../CardDisplay/CardDisplay';

export class HandList extends React.Component {
    showCards(cards) {
        return cards.map((card, index) => 
            <CardDisplay 
                key={index} 
                card={card} 
                playState={this.props.playState}
                activePlayers={this.props.activePlayers}/>
        );
    }
    
    render() {
       return (
        <div className="player-hand">
            {this.showCards(this.props.playerhand)}
        </div>
       ) 
    };


}