import React from 'react';
import './DiscardPile.css';

export class DiscardPile extends React.Component {

    discardElements(pile) {
        return pile.map((card, index) => 
            <li key={index}>{card.name}</li>
        );
    }

    render(){
        return (
            <div className="discard-pile">
                <p>Discard Pile:</p>
                <ul id="discard-list">
                     {this.discardElements(this.props.pile)}
                </ul>
              {this.props.deck.length === 0 && (
                <ul>
                  <li key={this.props.burned.name}>Burn Card: {this.props.burned.name}</li>
                </ul>
              )}
            </div>
        );
    }
}

