import React from 'react';
import './CardDisplay.css';
import { Button } from '../Button/Button';
import { Dropdown } from '../Dropdown/Dropdown';

export class CardDisplay extends React.Component {
      render() {
         return (
            <div className="card-container">
                <p className="card-name">{this.props.card.name}</p>
                <img 
                  className="card-display" 
                  src={this.props.card.imgsrc}
                  alt={this.props.card.name}
                  />

               {this.props.playState && (
                  <div>
                     {!this.props.card.targetable && (
                        <Button 
                           onButtonClick={this.moveToNextPlayer}
                           className="card-action">
                           Play Card
                        </Button>
                     )}
                     {this.props.card.targetable && (
                        <Dropdown activePlayers={this.props.activePlayers}/>
                     )}
                  </div>
               )}
               
         
            </div>
         ) 
      };


}